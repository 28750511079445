import React from "react";

import Contact from "../components/contact";
import Input from "../components/input";
import Radio from "../components/radioInput";
import data from "../../content/forms/contact.json";

const eventTypeOptions = [
  { value: "in person", label: "In Person" },
  { value: "virtual", label: "Virtual" },
  { value: "both", label: "Both" },
];

const eventTimeframeOptions = [
  { value: "next week", label: "Next Week" },
  { value: "next month", label: "Next Month" },
  { value: "future", label: "More Than a Month Away" },
];

const Form = () => {
  return (
    <Contact
      data={data}
      formURL="https://formkeep.com/f/a2d4802996ae"
      gridSize="2"
      googleTag="contact"
    >
      <Input type="text" name="name" label="Full Name" required={true} />
      <Input
        type="text"
        name="organization"
        label="Organization Name"
        required={true}
      />
      <Input type="email" name="email" label="Email" required={true} />
      <Input
        type="text"
        name="events per year"
        label="Number of Events This Year"
        required={true}
      />
      <Radio
        name="event type"
        options={eventTypeOptions}
        label="Type of Events"
        extraClasses="sm:col-span-2"
        required={true}
      />
      <Radio
        name="event timeframe"
        options={eventTimeframeOptions}
        label="When is your next event?"
        extraClasses="sm:col-span-2"
        required={true}
      />

      <div className="sm:col-span-2">
        <Input type="textarea" name="message" label="Other Details / Message" />
      </div>
    </Contact>
  );
};

export default Form;
