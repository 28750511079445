import PropTypes from "prop-types";
import React from "react";
import Required from "./required";

const RadioInput = ({ options, name, label, extraClasses, required }) => {
  const inputs = [];
  options.forEach((option) => {
    inputs.push(
      <span className="block sm:inline" key={option.value}>
        <input
          type="radio"
          name={name}
          value={option.value}
          required={required}
        />
        <label className="pl-2 pr-6 py-2" htmlFor={option.value}>
          {option.label}
        </label>
      </span>
    );
  });
  return (
    <div className={`tracking-wide ${extraClasses}`}>
      <p className="text-lg flex items-center">
        {label} {required && <Required />}
      </p>
      <div className="border rounded px-2 py-4">{inputs}</div>
    </div>
  );
};

RadioInput.propTypes = {
  options: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  extraClasses: PropTypes.string,
  required: PropTypes.bool,
};

export default RadioInput;
